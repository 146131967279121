.btn-link-form{
  display: block;
  padding: 7px 18px;
  color: #666;
  transition: all 0.5s;
  font-size: 14px;
  line-height: 25px;
  clear: both;
  font-weight: normal;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  i{
    float: left;
    margin-right: 7px;
    margin-top: 2px;
    font-size: 20px;
  }
}
.btn-link-top{
  padding: 7px 7px 2px 7px;
  margin-top: 17px;
  margin-left: 5px;
  background: none;
  color: #fff;
  border: 0;
}
@media screen and (max-width: 767px){
  .table-responsive {
    border: 0;
  }
}
.alert{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  button.close{
    top: 11px !important;
    right: 20px !important;
    font-size: 30px;
    font-weight: normal;
  }
  .material-icons{
    float: left;
  }
}
.alert-success{
  background-color: #5bc75b;
}
.alert-error{
  background-color: #e86257;
}
.alert-warning{
  background-color: #f1a840;
}
.alert-info{
  background-color: #4eafcc;
}
.alert.login-alert{
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}
.switch input {display:none;}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.pager{
  margin-bottom: 0;
}
.sidebar .menu .list .ml-menu li a{
  transition: all 0.4s linear;
}
.dropzone .dz-preview.dz-file-preview .dz-image{
  border-radius: 5px;
  background: #c1d4c1;
}
.sweet-alert{
  border-radius: 3px;
}
.sweet-alert button{
  border-radius: 3px !important;
}
.croppie-container .cr-boundary{
  margin: 0;
}
.croppie-container .cr-slider-wrap{
  margin: 15px 0;
  width: 400px;
}
.spinner {
  height:20px;
  width:20px;
  position: absolute;
  top:50%;
  left:50%;
  margin: -(20px/2) 0 0 -(20px/2);
   -webkit-animation: rotation 1s infinite linear;
   -moz-animation: rotation 1s infinite linear;
   -o-animation: rotation 1s infinite linear;
   animation: rotation 1s infinite linear;
   border:3px solid rgba(0,0,0,.2);
   border-radius:100%;
}

.spinner:before {
   content:"";
   display:block;
   position:absolute;
   left:-3px;
   top:-3px;
   height:20px;
   width:20px;
   border-top:3px solid rgba(255,255,255,.8);
   border-left:3px solid transparent;
   border-bottom:3px solid transparent;
   border-right:3px solid transparent;
   border-radius:100%;
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
  }
