.theme-cyan .navbar {
  background-color: #00BCD4; }

.theme-cyan .navbar-brand {
  color: #fff; }
  .theme-cyan .navbar-brand:hover {
    color: #fff; }
  .theme-cyan .navbar-brand:active {
    color: #fff; }
  .theme-cyan .navbar-brand:focus {
    color: #fff; }

.theme-cyan .nav > li > a {
  color: #fff; }
  .theme-cyan .nav > li > a:hover {
    background-color: transparent; }
  .theme-cyan .nav > li > a:focus {
    background-color: transparent; }

.theme-cyan .nav .open > a {
  background-color: transparent; }
  .theme-cyan .nav .open > a:hover {
    background-color: transparent; }
  .theme-cyan .nav .open > a:focus {
    background-color: transparent; }

.theme-cyan .bars {
  color: #fff; }

.theme-cyan .sidebar .menu .list li.active {
  background-color: transparent; }
  .theme-cyan .sidebar .menu .list li.active > :first-child i, .theme-cyan .sidebar .menu .list li.active > :first-child span {
    color: #00BCD4; }

.theme-cyan .sidebar .menu .list .toggled {
  background-color: transparent; }

.theme-cyan .sidebar .menu .list .ml-menu {
  background-color: transparent; }

.theme-cyan .sidebar .legal {
  background-color: #fff; }
  .theme-cyan .sidebar .legal .copyright a {
    color: #00BCD4 !important; }

